/*Desktop View*/
.nav-header {
  display: flex;
  background-color: "white";
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  padding-left: 15%;
  padding-right: 15%;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1;
}
.logo-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 6;
}
.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo {
  max-width: 80%;
  max-height: 80%;
  opacity: 1;
}
.nav-options {
  padding-left: 25px;
  padding-right: 50px;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 50px;
  list-style-type: none;
}
.mobile-option {
  display: none;
  visibility: hidden;
}

.option {
  text-decoration: none;
}
.option > a {
  color: #242f51;
  text-decoration: none;
  font-size: 1.1em;
}

.option :hover {
  color: #83c44e;
}

.option-login {
  color: #ffff;
}

.option-login > a {
  color: #ffff;
}

.option-login :hover {
  color: #242f51;
}

.signin-up {
  list-style-type: none;
}

.signup-btn {
  /* border-radius: 3px; */
  background-color: #83c44e;
  font-weight: 800;
  padding: 17px 36px 17px 36px;
  border-radius: 50px;
  font-size: 1rem;
  text-decoration: none;
  color: white;
  cursor: pointer;
}
.mobile-menu {
  display: none;
}
@media (max-width: 648px) {
  .nav-header {
    padding-left: 7%;
    padding-right: 2%;
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .header {
    padding: 0px 10px;
  }
  .logo {
    max-width: 100%;
    max-height: 100%;
    opacity: 1;
    width: 45px;
    height: 45px;
  }
  .nav-options {
    display: flex;
    width: 100%;
    padding-right: 0px;
    position: absolute;
    top: 63px;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
    flex-direction: column;
    list-style-type: none;
    grid-gap: 0px;
  }
  .nav-options.active {
    /* background: #3a5869; */
    background-color: "red";
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    align-content: center;
    padding-left: 0px;
    padding-right: 0px;
  }

  .menu-icon {
    width: 35px;
    height: 35px;
  }
  .option {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0px 15px 0px;
    /* background: #3a5869; */
    background-color: white;
  }

  .signin-up {
    display: none;
  }

  .mobile-option {
    background-color: #83c44e;
    display: block;
    visibility: visible;
    text-align: center;
  }

  .mobile-menu {
    display: block;
  }
}
