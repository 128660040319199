.explore_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 15%;
  padding-right: 10%;
}

.explore_card_container {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 15px;
}

.home_card_image {
  width: 100px;
}

.home_card_title {
  font-size: 1vw;
  font-weight: 700;
  color: #242f51;
  width: 80%;
  margin: 10px 0;
}

.home_card_helper {
  font-size: 1vw;
  width: 80%;
  color: #242f51;
}

.explore_img_container {
  width: 50%;
}

.explore_img {
  width: 100%;
}

.explore_second_content_view {
  background-image: url("../../../../../assets/images/Rectangle.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 100% 50%;
  /* padding-top: 100px; */
}

.explore_second_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 15%;
  padding-right: 10%;
  padding-top: 50px;
}

.explore_second_content_title {
  /* font-size: 4em; */
  font-size: 3vw;
  line-height: 1.2;
  font-weight: 700;
  color: #242f51;
}

.explore_second_mobile_container {
  margin-top: 100px;
}

.explore_mobile_img {
  max-width: 40%;
  max-height: 40%;
  margin-left: 20px;
}

.explore_img_two {
  width: 80%;
}

@media screen and (max-width: 636px) {
  .explore_container {
    display: flex;
    flex-direction: column;
    padding-left: 2%;
    padding-right: 2%;
  }

  .explore_card_container {
    width: 100%;
    display: flex;
    margin-top: 15px;
  }

  .expore-content-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin-bottom: 40px;
    /* align-items: center; */
  }

  .explore_img_container {
    width: 100%;
  }

  .home_card_title {
    /* font-size: 1.4em; */
    font-size: 4vw;
    margin: 10px 0;
    text-align: center;
  }

  .explore_second_content_view {
    background-size: 100% 20%;
    padding-top: 50px;
  }

  .explore_second_content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 2%;
    padding-right: 2%;
  }

  .explore_second_content_item {
    width: 100%;
  }

  .explore_second_content_title {
    font-size: 2em;
    line-height: 1.1;
  }

  .explore_second_mobile_container {
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .explore_mobile_img {
    max-width: 150px;
    max-height: 50px;
    margin-left: 0px;
    margin-top: 0px;
  }

  .explore_img_two {
    display: none;
    width: 80%;
    margin-top: 30px;
  }
}
