.contact-container {
  padding-left: 15%;
  padding-right: 15%;
}

.contact-container-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.contact-container-mainText {
  font-size: 4em;
  color: #007ac3;
  font-weight: 700;
  margin-right: 20px;
}

.contact-message-image {
  width: 80px;
  height: 50px;
}

.contact-container-helperText {
  font-size: 1.4em;
  color: #616368;
  text-align: center;
}

.contact-communication-container {
  background-color: #83c44e;
  padding: 40px 60px;
  border-radius: 15px;
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contact-communication-card {
  background-color: #93e15a;
  font-size: 1.4em;
  color: #ffffff;
  padding: 15px 50px;
  border-radius: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.contact-communication-card > a {
  text-decoration: none;
  color: #ffffff;
}

.contact-communication-card > a :hover {
  color: #007ac3;
}

@media screen and (max-width: 636px) {
  .contact-container {
    padding-left: 2%;
    padding-right: 2%;
  }

  .contact-message-image {
    width: 50px;
    height: 30px;
  }

  .contact-container-mainText {
    font-size: 2em;
    margin-right: 10px;
  }

  .contact-container-helperText {
    font-size: 1.1em;
  }

  .contact-communication-container {
    padding: 10px 20px;

    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .contact-communication-card {
    font-size: 1.1em;
    color: #ffffff;
    padding: 10px 0px;
    width: 90%;
    margin-bottom: 30px;
    text-align: center;
  }

  .contact-communication-card > a {
    width: 90%;
  }
  .contact-communication-card:first-child {
    margin-top: 30px;
  }
}
