.general-container {
  padding-left: 15%;
  padding-right: 15%;
}

.general-mainText {
  font-size: 4em;
  color: #007ac3;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
}

.general-helperText {
  font-size: 1.4em;
  color: #616368;
  text-align: center;
  text-align: left;
}

@media screen and (max-width: 636px) {
  .general-container {
    padding-left: 2%;
    padding-right: 2%;
  }

  .general-mainText {
    font-size: 2em;
    margin-right: 10px;
  }

  .general-helperText {
    font-size: 1.1em;
  }
}
