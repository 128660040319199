.heroContainer {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: inherit;
  padding-top: 20px;
  padding-bottom: 250px;
  padding-left: 15%;
  padding-right: 10%;
}

.hero_container_view {
  background-image: url("../../../../../assets/images/Rectangle.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 100% 50%;
}

.hero_header {
  color: #007ac3;
  letter-spacing: 0px;
  text-align: left;
  /* font-size: xx-large; */
  font-size: 4vw;
  /* font-size: 50px; */
  font-weight: bold;
  line-height: 1.1;
}

.hero_subHeader {
  display: flex;
  flex-direction: row;
  text-align: left;
  letter-spacing: 0px;
  margin-top: 20px;
  color: #616368;
  font-size: 1.5vw;
}

.hero_subHeader > span {
  color: #616368;
  font-weight: 600;
  margin-left: 5px;
}

.hero_content {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px;
}

.hero_btn {
  background-color: #83c44e;
  font-weight: 800;
  padding: 17px 36px 17px 36px;
  border-radius: 50px;
  font-size: 1vw;
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.hero_howItWorks {
  margin-left: 30px;
  margin-right: 7px;
  /* font-size: 1em; */
  font-size: 1vw;
  cursor: pointer;
}

.hero_howItWorks:hover {
  color: #83c44e;
}

.hero_image {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.hero_stow_img {
  width: 45%;
  /* width: 500px;
    height: 400px; */
}

.hero-play-video {
  width: 1080px;
  height: 720px;
}

@media screen and (max-width: 636px) {
  .heroContainer {
    flex-direction: column;
    align-items: center;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 100px;
  }

  .hero_subHeader {
    letter-spacing: 0px;
    margin-top: 30px;
    color: #616368;
    /* font-size: 1.2em; */
    font-size: 4vw;
  }

  .hero_btn {
    padding: 10px 20px 10px 20px;
    font-size: 4vw;
  }

  .hero_howItWorks {
    margin-left: 5px;
    margin-right: 16px;
    /* font-size: 1em; */
    font-size: 4vw;
  }

  .hero_stow_img {
    width: 90%;
    margin-top: 60px;
  }

  .hero_container_view {
    background-size: 100% 20%;
  }

  .hero_header {
    font-size: 3em;
    font-weight: bold;
    line-height: 1.1;
  }

  .hero-play-video {
    width: 300px;
    height: 200px;
  }
}
