.faq-home-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.faq-home-container {
  padding-right: 15%;
  padding-left: 15%;
}

.faq-support-text-have-question {
  font-size: 4em;
  color: #007ac3;
  font-weight: 700;
}

.faq-input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #d5e3f1;
  height: 90px;
  border-radius: 20px;
  margin-top: 70px;
  margin-bottom: 80px;
}

.faq-search-icon {
  color: #007ac3;
  margin-left: 35px;
}

input {
  width: 98%;
  height: 100%;
  outline: 0;
  border: 1px solid transparent;
  background-color: transparent;
}

input,
input::-webkit-input-placeholder {
  font-size: 1.5em;
  color: #007ac3;
}

input:focus {
  outline: 0;
}

.faq-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faq-need-help {
  font-weight: 600;
  font-size: 4em;
  color: #242f51;
  margin-bottom: 10px;
}

.faq-support-text {
  font-size: 1.4em;
  color: #616368;
}

/* .faq-support-text:first-child {
  margin-top: 70px;
} */

.faq-support-contact {
  margin-top: 40px;
  padding: 20px 60px;
  background-color: #83c44e;
  font-size: 1.2em;
  font-weight: 500;
  color: #ffffff;
  border-radius: 15px;
  cursor: pointer;
}

.faq-support-contact > a {
  text-decoration: none;
  color: #ffffff;
}

/*  */
.faq-container {
  border: 1px solid #f3f4f7;
  background-color: #f5f7fa;
  padding: 65px 40px;
  margin-bottom: 15px;
}

.faq-container-mini {
  border: 1px solid #f3f4f7;
  background-color: #f5f7fa;
  padding: 33px 40px 20px 40px;
  margin-bottom: 15px;
}

.faq-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.faq-title {
  font-size: 1.5em;
  font-weight: 700;
  margin-bottom: 15px;
  color: #242f51;
}

.faq-expansion-icon {
  color: #242f51;
}

.faq-helper {
  font-size: 1.2em;
}

.faq-helper-none {
  display: none;
}

@media screen and (max-width: 636px) {
  .faq-home-container {
    padding-right: 2%;
    padding-left: 2%;
  }

  .faq-support-text-have-question {
    font-size: 2em;
    line-height: 1.1;
  }

  .faq-support-text {
    font-size: 1em;
    margin-top: 5px;
  }

  .faq-input-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #d5e3f1;
    height: 60px;
    border-radius: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  input,
  input::-webkit-input-placeholder {
    font-size: 1.2em;
    color: #007ac3;
  }

  .faq-search-icon {
    color: #007ac3;
    margin-left: 20px;
  }

  /* FAQ CARD */
  .faq-container {
    padding: 40px 20px;
  }

  .faq-container-mini {
    padding: 20px 30px 20px 30px;
  }

  .faq-title {
    font-size: 1.2em;
  }

  .faq-helper {
    font-size: 1em;
  }

  .faq-need-help {
    font-weight: 600;
    font-size: 2em;
    color: #242f51;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .faq-support-contact {
    margin-top: 40px;
    padding: 10px 30px;
  }
}
