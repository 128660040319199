.footer-view {
  background-image: url("../../../../../assets/images/footer-key.png");
  height: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 20% 60%;
  background-color: #007ac3;
}

.footer-view > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-tabs {
  color: #ffffff;
  /* font-size: 1.2em; */
  font-size: 1vw;
  margin-right: 30px;
  cursor: pointer;
}

.footer-tabs > a {
  text-decoration: none;
  color: #ffffff;
}

.footer-tabs :hover {
  color: #7dc244;
}

.footer-tabs > a :hover {
  color: #7dc244;
}

.footer-icons-container {
  display: flex;
  flex-direction: row;
  margin: 50px 0;
}

.footer-icon {
  color: white;
  font-size: 3em;
  margin-right: 10px;
  cursor: pointer;
}

.footer-icon:hover {
  /* color: #ffffff; */
  color: #7dc244;
  /* opacity: 0.21; */
  /* font-size: 1em; */
}

.footer-copy-right {
  color: #ffffff;
  opacity: 0.21;
  font-size: 1vw;
}

.footer-copy-right-span {
  margin-left: 2px;
  margin-right: 2px;
}

@media screen and (max-width: 636px) {
  .footer-view {
    height: 200px;
    background-size: 20% 60%;
    background-color: #007ac3;
  }
  .footer-view > div {
    padding-left: 2%;
  }
  .footer-tabs {
    color: #ffffff;
    font-size: 3vw;
    margin-right: 10px;
    /* border: 1px solid red; */
  }

  .footer-icons-container {
    display: flex;
    flex-direction: row;
    margin: 20px 0;
  }

  .footer-copy-right {
    font-size: 3vw;
  }
}
